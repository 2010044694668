import React from 'react';
import ReactPlayer from 'react-player';
import Banner from "../components/banner"
import sanitizeHtml from 'sanitize-html';

import Layout from "../components/layout"

const InsightVideoPage = ({ pageContext }) => {
    return (
        <Layout>
            <Banner
                mediaType="Video"
                mediaDuration={pageContext.duration}
                publishedDate={`${getLongDate(pageContext.date).month} ${getLongDate(pageContext.date).date}, ${getLongDate(pageContext.date).year}`}
                mediaText={pageContext.title}
            />

            <section className="container">
                <div className="insight-video">
                    <div className="player-wrapper">
                        <ReactPlayer
                            url={pageContext.videoUrl}
                            controls={true}
                            width='100%'
                            height='100%'
                            className="react-player"
                        />
                    </div>
                    <div
                        className="insight-video-content"
                        dangerouslySetInnerHTML={
                            {
                                __html: sanitizeHtml(pageContext.content, {
                                    allowedTags: ['div', 'h1', 'h2', 'h3', 'p', 'b', 'i', 'ul', 'li'],
                                })
                            }}
                    ></div>
                </div>
            </section>
        </Layout>
    )
}

const getLongDate = (date) => {

    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const fullDate = new Date(Date.parse(date));
    let eventMonth = monthNames[fullDate.getMonth()];
    let eventDate = fullDate.getDate();
    let eventYear = fullDate.getFullYear();

    return {
        month: eventMonth,
        date: eventDate,
        ordinal: getOrdinal(eventDate),
        year: eventYear
    }
}

const getOrdinal = (date) => {

    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
    }
}

export default InsightVideoPage
